import React, { useEffect } from 'react';
import i18n from "../../../i18n";
import { useDispatch, useSelector } from 'react-redux';
import { Link, graphql } from 'gatsby';
import { useCart } from 'react-use-cart';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import NavBarMenu from '../../components/NavBarMenu';
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { getPreAuthorizationLaunched } from '../../state/reducer';
import { translatePaymentError } from '../../utils/libs/mangopay';
import CustomLoader from '../../components/CustomLoader';
import generateMeta from '../../utils/meta';

const PaymentStatusPage = ({ data, pageContext, t }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  const lang = pageContext.locale;
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];
  const { emptyCart } = useCart();
  // i18n used for translation
  i18n(pageContext.locale);

  let preAuthorizationId;
  if (typeof window !== "undefined") {
    const query = new URLSearchParams(window.location.search);
    preAuthorizationId = query.get("preAuthorizationId")
  }
  const dispatch = useDispatch();

  const { getPreAuthorizationContent, getPreAuthorizationLoading } = useSelector((state) => ({
    getPreAuthorizationContent: state.getIn(["root", "getPreAuthorizationContent"]),
    getPreAuthorizationLoading: state.getIn(["root", "getPreAuthorizationLoading"]),
  }));

  useEffect(() => { // permits to not create an infinity loop and to rate limit mangopay
    if (!getPreAuthorizationContent) {
      dispatch(getPreAuthorizationLaunched({ preAuthorizationId: preAuthorizationId }));
    }
  }, []);

  useEffect(() => {
    if (getPreAuthorizationContent && getPreAuthorizationContent.Status === "SUCCEEDED") {
      emptyCart();
    }
  }, [getPreAuthorizationContent])


  return (
    <Layout
      lang={lang}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang={lang} />
        <title>Statut de paiement</title>
        {/* Seo and OpenGraph */}
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css"
          integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=="
          crossorigin=""
        />
        <script
          src="https://unpkg.com/leaflet@1.8.0/dist/leaflet.js"
          integrity="sha512-BB3hKbKWOc9Ez/TAwyWxNXeoV9c1v6FIeYiBieIWkpLjauysF18NzgR1MBNBXf8/KABdlkX68nAhlwcDFLGPCQ=="
          crossorigin=""></script>
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={lang}
      />

      <div className="max-w-screen-2xl mx-auto">
        {(getPreAuthorizationContent && getPreAuthorizationContent.Status !== "SUCCEEDED") ? (
          <Link to="/" className="pl-6 flex text-grey items-center underline">
            <ChevronLeftIcon
              className="flex-shrink-0 h-4 w-4 text-grey"
              aria-hidden="true"
            />
            Continuer mes achats
          </Link>
        ) :
          <Link to="/" className="pl-6 flex text-grey items-center underline">
            <ChevronLeftIcon
              className="flex-shrink-0 h-4 w-4 text-grey"
              aria-hidden="true"
            />
            Retour à l'accueil
          </Link>
        }
        <>
          <h2 className='text-lg md:text-xl font-bold px-5 md:px-24 pt-4'>Détails de la <span className="text-orange-dark">transaction</span></h2>

          {
            (preAuthorizationId && getPreAuthorizationContent)
            && (
              <>
                <div className="flex flex-col items-center w-full px-4 py-4 md:pt-6 md:px-8 mb-4">
                  {
                    (getPreAuthorizationContent.Status === "SUCCEEDED") // Success
                    && (
                      <>
                        <div className="text-s md:text-base font-medium flex flex-col space-y-1 items-center">
                          Merci {getPreAuthorizationContent?.Shipping?.FirstName} {getPreAuthorizationContent?.Shipping?.LastName} pour votre commande.
                          <span className="font-normal">La transaction n°{preAuthorizationId} a bien été validée.</span>
                          <span className="font-normal text-s">Vous allez recevoir une confirmation de commande par mail.</span>
                        </div>
                        <div className="flex flex-col lg:flex-row py-8 lg:py-10 space-y-4 lg:space-y-0 lg:space-x-4">
                          <Link to={`/${lang}/order/`} className=" text-s px-2 py-0.5 lg:py-2 text-gray-700">
                            <button type="button" className="outline-none pt-4 bg-orange-chocolate w-full  rounded flex text-white px-2 py-4 justify-around hover:font-medium">
                              <span>Suivi de mes commandes</span>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </button>
                          </Link>
                          <Link to={`/${lang}/`} className=" text-s px-2 py-0.5 lg:py-2 text-gray-700">
                            <button type="button" className="outline-none pt-4 bg-orange-chocolate w-full  rounded flex text-white px-2 py-4 justify-around hover:font-medium">
                              <span>Revenir à la page d'accueil</span>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </button>
                          </Link>
                        </div>
                      </>
                    )
                  }
                  {
                    (getPreAuthorizationContent.Status !== "SUCCEEDED") // Fail
                    && (
                      <>
                        <div className="text-s md:text-base font-medium text-orange-darkest">
                          <p>Une erreur est survenue avec la transaction n°{preAuthorizationId} :</p>
                          <p>{translatePaymentError(getPreAuthorizationContent.ResultMessage)} </p>
                        </div>
                        <div className="text-s md:text-base text-center font-medium pt-4 flex flex-col space-y-1">
                          <span>Votre commande n'a pas pu être enregistrée</span>
                          <span>Veuillez réessayer le paiement</span>
                        </div>
                        <div className="flex flex-col lg:flex-row py-8 lg:py-10">
                          <Link to={`/${lang}/checkout/`} className=" text-s px-2 py-0.5 lg:py-2 text-gray-700">
                            <button type="button" className="outline-none pt-4 bg-orange-chocolate w-full rounded flex text-white px-2 py-4 justify-around hover:font-medium">
                              <span>Revenir à la page de paiement</span>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </button>
                          </Link>
                        </div>
                      </>
                    )
                  }
                </div>
              </>
            )
          }
          {
            (!getPreAuthorizationContent && !getPreAuthorizationLoading)
            && (
              <>
                <div className="flex flex-col items-center w-full px-4 py-4 md:pt-6 md:px-8 mb-4">
                  <div className="text-s md:text-base text-center font-medium pt-4 flex flex-col space-y-1">
                    <span>Pas de transaction trouvée avec ces éléments</span>
                    <span>Veuillez réessayer le paiement</span>
                  </div>
                  <div className="flex flex-col lg:flex-row py-8 lg:py-10">
                    <Link to={`/${lang}/checkout/`} className=" text-s px-2 py-0.5 lg:py-2 text-gray-700">
                      <button type="button" className="outline-none pt-4 bg-orange-chocolate w-full rounded flex text-white px-2 py-4 justify-around hover:font-medium">
                        <span>Revenir à la page de paiement</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </>
            )
          }
          {getPreAuthorizationLoading ? <CustomLoader /> : null}
        </>
      </div>
    </Layout>
  )
}

export default PaymentStatusPage

export const query = graphql`
    query( $locale: String! ) {
        menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
              node {
                slug
                categoryName
              }
            }
          }
        menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                }
              }
            }
          }
        allContentfulCategorySve(filter: { node_locale: { eq: $locale } }) {
            edges {
              node {
                categoryName
                categoryEncartTitle
                slug
                parentCategory {
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        markdownRemark(fileAbsolutePath: {regex: "/confirmation.md/"}) {
          html
          frontmatter {
              title
              description
              ogtitle
              ogdescription
          }
        }
    }
`